export const send = (data, url, responseType = 'json') => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', url); // Replace with your server address and API endpoint

        xhr.onreadystatechange = function () {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                if (xhr.status === 200) {
                    // console.log('Data sent successfully');
                    let response;
                    if (responseType === 'json') {
                        response = JSON.parse(xhr.responseText);
                    } else if (responseType === 'blob') {
                        response = xhr.response;
                    } else {
                        response = xhr.responseText;
                    }
                    resolve(response); // Resolve the promise with the response data
                } else {
                    // console.log('Data sent failed');
                    reject(new Error('Request failed')); // Reject the promise with an error
                }
            }
        };

        if (responseType === 'blob') {
            xhr.responseType = 'blob';
        }

        const formData = new FormData();
        for (const n in data) {
            const v = data[n];
            const t = typeof v;
            // console.log("n(" + t + ")=" + v);
            if (t === "string" || t === "number") {
                formData.append(n, v);
            }
        }
        xhr.send(formData);
    });
};
