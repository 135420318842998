import { createApp } from 'vue'
import Vue from 'vue'
import App from './App.vue'
import router from './router/AllRouters'
import store from './store/UserStore'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import config from '@/config'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

let app = createApp(App)
app.use(store)
app.use(router)
app.use(ElementPlus)
app.use(ElementPlusIconsVue)
app.mount('#app')

//app.config.globalProperties.$config = config


for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

//createApp(App).use(store).use(router).mount('#app')

