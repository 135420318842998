<template>
  <div class="common-layout">
    <el-container>
      <!--Header-->
      <el-header class="header" style="border-bottom: 1px solid #DDDFE3;">

        <div class="header-left">
          <el-icon class="el-icon--left" @click="toggleSidebar">
            <Operation/>
          </el-icon>
          <img src='../assets/title.png' alt="Title" style="width: 115px; height: 45px;">
        </div>

        <div class="header-right">
          <el-dropdown :hide-on-click="false">
            <span class="el-dropdown-link" style="color:black;">
              <el-icon class="el-icon--right icon"><UserFilled/></el-icon>
              {{ loginName }}
              <el-icon
                  class="el-icon--right icon"><CaretBottom/></el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="updatePassword">
                  <el-icon><Lock /></el-icon>
                  Change Password
                </el-dropdown-item>
                <el-dropdown-item divided @click="logout">Log Out</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>

        </div>
      </el-header>


      <!--SideBar-->
      <el-container>
        <el-aside class="aside sidebar" :class="{ 'collapsed': isCollapsed }">

          <el-menu class="sidebarContent" background-color="transparent" active-text-color="#0a60f5" :default-active="activeMenu" :default-openeds="openMenus" @select="handleMenuSelect" @open="handleMenuOpen">

            <el-menu-item index="1" class="menu-item" @click="MainView">
              <span class="menu-item-text">
                <el-icon><HomeFilled /></el-icon>
                Home
              </span>
            </el-menu-item>

            <el-sub-menu index="2" class="menu-item">
              <template #title>
                <span class="menu-item-text">
                  <el-icon><DocumentAdd /></el-icon>
                  Payments
                </span>
              </template>
              <el-menu-item-group class="menu-item">
                <el-menu-item index="2-1" class="submenu-item-text" @click="ApiOutgoingPaymentView">
                  <el-icon><Money /></el-icon>
                  Disbursement
                </el-menu-item>
                <el-menu-item index="2-2" class="submenu-item-text" @click="TransactionView">
                  <el-icon><ChatLineSquare /></el-icon>
                  Transaction
                </el-menu-item>
                <el-menu-item index="2-3" class="submenu-item-text" @click="SettlementView">
                  <el-icon><Money /></el-icon>
                  Settlement
                </el-menu-item>
                <el-menu-item index="2-4" class="submenu-item-text" @click="ChargebackView">
                  <el-icon><Money /></el-icon>
                  Chargeback
                </el-menu-item>
              </el-menu-item-group>
            </el-sub-menu>
          </el-menu>
        </el-aside>
        <el-main>
          <slot></slot>
        </el-main>
      </el-container>
    </el-container>
  </div>

  <el-dialog v-model="updatePasswordData.visible" :title="'Change password'" :close-on-click-modal="false" :close-on-press-escape="false"  style="height: 400px; width:600px; font-weight:bold; border-radius: 6px">
    <el-form ref="updatePasswordModalForm" :model="updatePasswordData.formData" :rules="updatePasswordData.formRule" :label-width="130">
      <div class="border">
        <div class="title">Change Password</div>
        <input id="not_pwd3" type="password" style="position: absolute;top: -999999px;">
        <el-form-item label="Old Password" prop="oldPassword">
          <el-input type="password" v-model="updatePasswordData.formData.oldPassword" show-password></el-input>
        </el-form-item>
        <el-form-item label="New Password" prop="newPassword">
          <el-input type="password" v-model="updatePasswordData.formData.newPassword" show-password></el-input>
        </el-form-item>
        <el-form-item label="Confirm Password" prop="confirmPwd">
          <el-input type="password" v-model="updatePasswordData.formData.confirmPwd" show-password></el-input>
        </el-form-item>
        <div class="Submission" style="padding-bottom: 15px">
          <el-button type="primary" style="background-color: #0a60f5; border: 1px solid #cecccc;" @click="updatePasswordData.visible = false">
          <el-icon><Close /></el-icon> Cancel
          </el-button>
          <el-button type="primary" style="background-color: #0a60f5; border: 1px solid #cecccc;" :loading="!updatePasswordData.loading"  @click="UpdatePasswordSubmit">Submit</el-button>
        </div>
      </div>
    </el-form>
  </el-dialog>


</template>

<script>

import {mapActions} from 'vuex';
import { updatePassword } from '@/api/UserApi';
import store from "@/store/UserStore";

export default {
  loginName: store.state.loginName,
  name: "SideMenu",

  data() {
    const validatePassCheck = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please enter the password again'))
      } else if (value !== this.updatePasswordData.formData.newPassword) {
        callback(new Error('Inconsistent password entered twice!'))
      } else {
        callback()
      }
    }
    return {
      loginName: store.state.loginName,
      isCollapsed: false,
      activeMenu: '',
      openMenus: [],
      updatePasswordData: {
        state: false,
        loading: true,
        formData: {
          oldPassword: '',
          newPassword: '',
          confirmPwd: '',
          logonToken: store.state.token
        },
        formRule: {
          oldPassword: [
            { required: true, message: 'Old password cannot be empty', trigger: 'blur' }
          ],
          newPassword: [
            { required: true, message: 'New password cannot be empty', trigger: 'blur' }
          ],
          confirmPwd: [
            { required: true, message: 'Please enter the password again', trigger: 'blur' },
            { validator: validatePassCheck, trigger: 'blur' }
          ]
        }
      }
    };
  },

  mounted: function () {
    const storedActiveMenu = localStorage.getItem('activeMenu');
    if (storedActiveMenu) {
      this.activeMenu = storedActiveMenu;
    }
  },

  methods: {
    ...mapActions([
      'userLogoutAction'
    ]),

    toggleSidebar() {
      this.isCollapsed = !this.isCollapsed;
    },
    MainView() {
      localStorage.setItem('activeMenu', '1');
      this.$router.push('/dashboard');
    },
    SettlementView() {
      this.$router.push('/settlement');
      localStorage.setItem('activeMenu', '2-3');
    },
    TransactionView() {
      localStorage.setItem('activeMenu', '2-2');
      this.$router.push('/transaction');
    },
    ApiOutgoingPaymentView(){
      localStorage.setItem('activeMenu', '2-1');
      this.$router.push('/disbursement');
    },
    ChargebackView(){
      localStorage.setItem('activeMenu', '2-4');
      this.$router.push('/chargeback');
    },
    logout() {
      this.userLogoutAction().then(() => {
        localStorage.removeItem('activeMenu');
        this.$router.push('/login');
      })
    },
    handleMenuSelect(index) {
      // 更新当前活动的主菜单
      this.activeMenu = index;
      // 更新展开的主菜单
      this.openMenus = [index];
      // 将 activeMenu 的值保存到本地存储
      localStorage.setItem('activeMenu', index);
    },
    handleMenuOpen(index) {
      // 更新展开的主菜单
      this.openMenus = [index];
    },
    updatePassword () {
      this.updatePasswordData.visible = true; // 打开对话框
    },
    UpdatePasswordSubmit() {
      this.$refs['updatePasswordModalForm'].validate((valid) => {
        if (valid) {
          let tempData = this.updatePasswordData.formData;
          let data = {
            newPassword: tempData.newPassword,
            oldPassword: tempData.oldPassword,
            logonToken: store.state.token
          };
          updatePassword(data)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success('Update Password Success');
                  this.updatePasswordData.visible = false;
                  this.$router.push({ path: '/login' });
                } else {
                  this.updatePasswordData.loading = false;
                  this.$message.error(res.msg)
                  this.$nextTick(() => {
                    this.updatePasswordData.loading = true;
                  });
                }
              })
              .catch((e) => {
                // console.log(e);
              });
        } else {
          this.updatePasswordData.loading = false;
          this.$nextTick(() => {
            this.updatePasswordData.loading = true;
          });
        }
      });
    },
    UpdatePasswordCancel () {
      this.resetUpdatePasswordModal();
    },
    resetUpdatePasswordModal () {
      this.updatePasswordData.formData = { oldPassword: '', newPassword: '', confirmPwd: '' }
      this.$refs['updatePasswordModalForm'].resetFields()
    },
  }
}
</script>

<style scoped>
::v-deep(.el-drawer__header) {
  margin-bottom: 0 !important;
}

::v-deep(.el-drawer) {
  width: 20% !important;
}

.drawer-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 18px;
  font-weight: bold;
}

.aside {
  color: black;
  height: 94vh;
  width: 260px;
  transition: width 0.3s ease;
  /*background-image: linear-gradient(to bottom, #dee2e6, #e1e4e8, #e3e7ea, #e6e9ed, #e9ecef);*/
  background-color: white;
  border-right: 1px solid #DDDFE3;
}

.el-button:hover {
  background-color: #5abcd8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.sidebarContent {
  background-color: transparent;
  width: 250px;
  border: none;
}

.menu-item-text {
  color: black;
  transition: color 0.3s ease;
}

.menu-item:hover .menu-item-text {
  color: #0a60f5;
}

.submenu-item-text {
  color: black;
  transition: color 0.3s ease;
}

.submenu-item:hover .submenu-item-text {
  color: #0a60f5;
}

.submenu-item2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.submenu-text2-top, .submenu-text2-bottom {
  color: black;
  transition: color 0.3s ease;
  line-height: 1.5;

}

.collapsed {
  width: 0;
}

.header {
  /*background-image: linear-gradient(to right, #dee2e6, #e1e4e8, #e3e7ea, #e6e9ed, #e9ecef);*/
  background-color: white;
  padding-top: 0px;
  color: black;
  height: 6vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-left {
  display: flex;
  align-items: center;
}

.icon {
  padding: 10px;
  color: black;
}

.title {
  position: absolute;
  top: -10px;
  left: 30px;
  font-size: 18px;
  background: white;
  width: 155px;
  text-align: center;
  font-weight: normal;
}

.border {
  border: 1px solid #DDDFE3;
  padding: 30px 40px 0;
  border-radius: 5px;
  margin-top: 20px;
  position: relative;
}
</style>