<template>
  <div id="app">
  <router-view/>
  </div>
</template>

<script>
import {onMounted} from "vue";
export default {
  name: 'App',
  setup() {
    // Update the title when the component is mounted
    onMounted(() => {
      document.title = 'FuntecPay';
    });
  },
}
</script>

<style>
#app {
  background-color: #f8f9f9;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 0;
  margin:0;
}

.el-table th,
.el-table td {
  font-size: 12px;
}

html,body{
  margin: 0;
  --el-form-label-font-size: 12px;
  --el-font-size-base: 12px;
}
</style>
