import { createStore } from 'vuex'

export const TOKEN_KEY = 'token'
export const ID_KEY = 'id'
export const NAME_KEY = 'loginName'

export default createStore({
  state: {
    loginName: sessionStorage.getItem(NAME_KEY) || '',
    token: sessionStorage.getItem(TOKEN_KEY) || '',
    Id: sessionStorage.getItem(ID_KEY) || ''
  },
  getters: {
  },
  mutations: {
    setUserName (state, loginName) {
      state.loginName = loginName
      sessionStorage.setItem(NAME_KEY, loginName)
    },
    setToken (state, token) {
      state.token = token
      sessionStorage.setItem(TOKEN_KEY, token)
    },
    setID (state, Id) {
      state.Id = Id
      sessionStorage.setItem(ID_KEY, Id)
    }
  },
  actions: {
    userLoginAction ({commit}, loginResultData) {//commit: 用来提交当前模块的mutations
      commit('setToken', loginResultData.logonToken)
      commit('setUserName', loginResultData.loginUserName)
      commit('setID', loginResultData.memberInfoId)
    },
    userLogoutAction ({ state, commit }) {
      return new Promise((resolve, reject) => {
        commit('setToken', '')
        commit('setUserName', '')
        commit('setID','')
        resolve()
      })
    },
  },
  modules: {
  }
});


