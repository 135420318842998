import {send} from "@/utility/HttpPoster";
import cfg from "@/config/index";


/**
 * Get Record List
 */
export const getMerSettleRecordList = (data) => {
    var url = cfg.baseUrl.api + "/merSettle/settleList";
    return send(data, url)
};
/**
 * Get Order List
 */
export const getOrderList = (data) => {
    var url = cfg.baseUrl.api + "/merSettle/settleOrderList"
    return send(data,url)
}

/**
 * Download the Record in Excel
 */
export const merSettleRecordExcelDown = (data) => {
    var url = cfg.baseUrl.api + "/merSettle/settleListExcelDownDate"
    return send(data,url,'blob')
}

/**
 * Download the Order Record in Excel
 */
export const merSettleRecordOrderExcelDown = (data) => {
    var url = cfg.baseUrl.api + "/merSettle/settleOrderListExcelDown"
    return send(data,url,'blob')
}

/**
 * CSV Download
 */
export const settlementCsvDown = (data) => {
    var url= cfg.baseUrl.api + "/merSettle/settlementCsvDown"
    return send(data,url)
}
/**
 * CSV Download Progress Check
 */
export const settlementCsvProgress = (data) => {
    var url= cfg.baseUrl.api + "/merSettle/settlementCsvProgress"
    return send(data,url)
}

/**
 * CSV Download
 */
export const settlementOrderCsvDown = (data) => {
    var url= cfg.baseUrl.api + "/merSettle/settlementOrderCsvDown"
    return send(data,url)
}