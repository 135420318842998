import {send} from "@/utility/HttpPoster";
import cfg from "@/config/index";

/**
 * Merchant Login
 */
export const login = (loginData) => {
    var url= cfg.baseUrl.api + "/mer/login"
    return send(loginData,url)
};


/**
 * Retrieve the dashboard data of user
 */
export const getDashboard = (data) => {
    var url= cfg.baseUrl.api + "/page/dashboard"
    return send(data,url)
}

/**
 * View google authentication code for payment
 */
export const updatePassword = (data) => {
    var url= cfg.baseUrl.api + "/mer/updatePassword"
    return send(data,url)
}