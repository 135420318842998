import {send} from "@/utility/HttpPoster";
import cfg from "@/config/index";

/**
 * Pay Order List
 */
export const getTransactionList = (data) => {
    var url= cfg.baseUrl.api + "/transaction/transactionList"
    return send(data,url)
};

/**
 * Download the Transaction Record in Excel
 */
export const transactionExcelDown = (data) => {
    var url = cfg.baseUrl.api + "/transaction/excelDownloadTransactionList"
    return send(data,url,'blob')
}

/**
 * CSV Download
 */
export const transactionCsvDown = (data) => {
    var url= cfg.baseUrl.api + "/transaction/transactionCsvDown"
    return send(data,url)
}
/**
 * CSV Download Progress Check
 */
export const transactionCsvProgress = (data) => {
    var url= cfg.baseUrl.api + "/transaction/transactionCsvProgress"
    return send(data,url)
}


/**
 * Api Outgoing Payment Order List
 */
export const getApiOutgoingPaymentList = (data) => {
    var url= cfg.baseUrl.api + "/disbursement/disbursementList"
    return send(data,url)
};

/**
 * Download the Transaction Record in Excel
 */
export const apiOutgoingPaymentExcelDown = (data) => {
    var url = cfg.baseUrl.api + "/disbursement/excelDownloadDisbursementList"
    return send(data,url,'blob')
}

/**
 * CSV Download
 */
export const disbursementCsvDown = (data) => {
    var url= cfg.baseUrl.api + "/disbursement/disbursementCsvDown"
    return send(data,url)
}
/**
 * CSV Download Progress Check
 */
export const disbursementCsvProgress = (data) => {
    var url= cfg.baseUrl.api + "/disbursement/disbursementCsvProgress"
    return send(data,url)
}

/**
 * Charge Back List
 */
export const getChargebackList = (data) => {
    var url= cfg.baseUrl.api + "/chargeback/chargebackList"
    return send(data,url)
};

/**
 * Download the Transaction Record in Excel
 */
export const chargebackExcelDown = (data) => {
    var url = cfg.baseUrl.api + "/chargeback/excelDownloadChargebackList"
    return send(data,url,'blob')
}

/**
 * CSV Download
 */
export const chargebackCsvDown = (data) => {
    var url= cfg.baseUrl.api + "/chargeback/chargebackCsvDown"
    return send(data,url)
}
/**
 * CSV Download Progress Check
 */
export const chargebackCsvProgress = (data) => {
    var url= cfg.baseUrl.api + "/chargeback/chargebackCsvProgress"
    return send(data,url)
}
