<template>
  <div class="main-view" >
    <SideMenu>
      <el-card :gutter="20" style="height: 98%;">
        <template #header>
          <div class="card-header">
            <span>Home</span>
          </div>
        </template>

        <el-row :gutter="20" class="row-spacing" :span="10">
          <el-col :span="6">
            <div class="card-content" >
              <div class="right-section">
                <p class="value center-align">
                  <i class="fa fa-rupee" style="font-size: 36px; margin-right: 5px;"></i>
                  {{ statistics.todayPayMoney }}
                </p>
                <p class="center-align">Today’s Successful Transactions</p>
              </div>
            </div>
          </el-col>

          <el-col :span="6">
            <div class="card-content">
              <div class="right-section center-align">
                <p class="value">
                  <i class="fa fa-rupee" style="font-size: 36px; margin-right: 5px;"></i>
                  {{ statistics.todaySettleMoney }}
                </p>
                <p>Today’s Settlements</p>
              </div>
            </div>
          </el-col>

          <el-col :span="6">
            <div class="card-content">
              <div class="right-section center-align">
                <p class="value">
                  <i class="fa fa-rupee" style="font-size: 36px; margin-right: 5px;"></i>
                  {{ statistics.merBalance }}
                </p>
                <p>Available Balance</p>
              </div>
            </div>
          </el-col>

          <el-col :span="6">
            <div class="card-content">
              <div class="right-section center-align">
                <p class="value">
                  <i class="fa fa-rupee" style="font-size: 36px; margin-right: 5px;"></i>
                  {{ statistics.unSettleMoney }}
                </p>
                <p>Unsettled</p>
              </div>
            </div>
          </el-col>

          <el-divider />

          <div class="subHeader">
            <span>Transactions</span>
          </div>

          <div class="right-section" style="display: flex; justify-content: flex-end; margin-right: 10px; margin-top: 10px">
            <el-form ref="listDataForm" :model="listData.filter">
              <el-row>
                <el-select v-model="selectedTimeRange" placeholder="Select Time Range" style="font-size: 12px; width: 140px;">
                  <el-option v-for="(range, index) in timeRanges" :key="index" :label="range.label" :value="range.value"></el-option>
                </el-select>
              </el-row>
            </el-form>
          </div>

          <div :loading="listData.loading" style="margin-top: 25px; max-height: 520px; overflow-y: auto; width: 100%; " >
            <div id="orderMoney" style="height: 50vh;"></div>
            <div id="orderCount" style="height: 50vh; "></div>
            <div id="orderRate" style="height: 50vh;"></div>
          </div>
        </el-row>
      </el-card>
    </SideMenu>
  </div>
</template>

<script>

import { mapActions } from 'vuex';
import { getDashboard} from "@/api/UserApi";
import store from "@/store/UserStore";
import SideMenu from "@/components/SideMenu";

import * as echarts from 'echarts'
import {orderPayStatDate} from "@/api/TransactionStatisticsApi";

export default {
  name: "MainView",

  components:{
    SideMenu
  },

  data () {
    return {
      statistics:{
        todayPayMoney: 0,
        merBalance: 0,
        unSettleMoney: 0,
        todaySettleMoney: 0,
      },
      selectedTimeRange: 'Last 7 days',

      timeRanges: [
        { label: 'Last 7 days', value: 'Last 7 days' },
      ],

      listData: {
        loading: false,
        filter: {
          startTime: '',
          logonToken: store.state.token,
          loginUserName: store.state.loginName,
        }
      },

      timeData: [],
      // total amount
      totalMoneyData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      // successful amount
      succMoneyData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      // fail amount
      failMoneyData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      // total order count
      totalCountData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      // total success count
      succCountData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      // total fail count
      failCountData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      // success rate
      succRateData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      // fail rate
      failRateData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },

  watch: {
    selectedTimeRange: {
      handler: 'search', // Call the search method when selectedTimeRange changes
      immediate: true,   // Call the handler immediately on component mount
    },
  },

  methods:{
    ...mapActions([
      'userLogoutAction'
    ]),

    calculateTimeData() {
      const currentDate = new Date();
      this.timeData = [];

      for (let i = 6; i >= 0; i--) {
        const date = new Date(currentDate);
        date.setDate(date.getDate() - i);
        const formattedDate = this.FormatDateToAPIFormat(date); // Use the same format as API
        this.timeData.push(formattedDate);
      }
    },

    loadList () {
      var data = {
        logonToken: store.state.token
      }

      getDashboard(data).then(res => {
        if(res.code == 0){
          this.statistics.todayPayMoney = res.data.todayPayMoney;
          this.statistics.todaySettleMoney = parseFloat(res.data.todaySettleMoney).toFixed(2);
          this.statistics.merBalance = parseFloat(res.data.merBalance).toFixed(2);
          this.statistics.unSettleMoney = parseFloat(res.data.unSettleMoney).toFixed(2);
        }
        else if(res.code == 18){
          this.userLogoutAction().then(() => {
            this.$router.push('/login');
          })
          this.$message.error(res.msg);
        }
        else{
          this.$message.error(res.msg);
        }
      }).catch((e) => {
        // console.log(e)
      })
    },

    loadGraph() {
      this.listData.loading = true;
      let tempFilter = this.listData.filter;

      if (tempFilter.startTime) {
        const parsedStartTime = new Date(tempFilter.startTime);
        tempFilter.startTime = this.formatDateToAPIFormat2(parsedStartTime);
      }

      orderPayStatDate(tempFilter)
          .then(res => {
            if (res.code == 0) {
              let tempData = res.data;

              const formattedData = tempData.map(item => {
                const parts = item.timeFlag.split(' ');
                const dateParts = parts[0].split('-');
                const formattedTimeFlag = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
                return {...item, timeFlag: formattedTimeFlag};
              });

              for (let i = 0; i < formattedData.length; i++) {
                const tempItem = formattedData[i];

                if (this.timeData.indexOf(tempItem.timeFlag) !== -1) {
                  let index = this.timeData.indexOf(tempItem.timeFlag);

                  this.totalMoneyData[index] = tempItem.totalMoney;
                  this.succMoneyData[index] = tempItem.succMoney;
                  this.failMoneyData[index] = tempItem.failMoney;
                  this.totalCountData[index] = tempItem.totalCount;
                  this.succCountData[index] = tempItem.succCount;
                  this.failCountData[index] = tempItem.failCount;

                  this.succRateData[index] = tempItem.totalCount !== 0 ? Math.round((tempItem.succCount / tempItem.totalCount) * 100) : 0;
                  this.failRateData[index] = tempItem.totalCount !== 0 ? Math.round((tempItem.failCount / tempItem.totalCount) * 100) : 0;
                }
              }

              this.listData.loading = false;
              this.OrderMoney();
              this.OrderCount();
              this.OrderRate();
            } else if (res.code == 18) {
              this.userLogoutAction().then(() => {
                this.$router.push('/login');
              });
              this.$message.error(res.msg);
            } else {
              this.listData.loading = false;
              this.$message.error(res.msg);
            }
          })
          .catch(err => {
            // console.log(err);
          });
    },


    // order revenue
    OrderMoney() {
      let orderMoney = echarts.init(document.getElementById('orderMoney'))
      let option

      option = {
        legend: {},
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '20%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.timeData
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'Total amount',
            data: this.totalMoneyData,
            type: 'line'
          },
          {
            name: 'Transaction succeeded amount',
            data: this.succMoneyData,
            type: 'line'
          },
          {
            name: 'Transaction failed amount',
            data: this.failMoneyData,
            type: 'line'
          }
        ]
      }
      window.addEventListener('resize', function() {
        orderMoney.resize();
      });
      option && orderMoney.setOption(option)
    },
    // number of orders
    OrderCount() {
      let orderCount = echarts.init(document.getElementById('orderCount'))
      let option

      option = {
        legend: {},
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '20%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.timeData
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'Total order volume',
            data: this.totalCountData,
            type: 'line'
          },
          {
            name: 'Succeeded order volume',
            data: this.succCountData,
            type: 'line'
          },
          {
            name: 'Failed order volume',
            data: this.failCountData,
            type: 'line'
          }
        ]
      }
      window.addEventListener('resize', function() {
        orderCount.resize();
      });
      option && orderCount.setOption(option)
    },
    // success rate or fail rate
    OrderRate() {
      let orderRate = echarts.init(document.getElementById('orderRate'))
      let option

      option = {
        legend: {},
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '20%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.timeData
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'Succeeded percentage rate',
            data: this.succRateData,
            type: 'line'
          },
          {
            name: 'Failed percentage rate',
            data: this.failRateData,
            type: 'line'
          }
        ]
      }
      window.addEventListener('resize', function() {
        orderRate.resize();
      });
      option && orderRate.setOption(option)
    },
    search() {
      const endDate = new Date();
      const startDate = new Date();
      startDate.setDate(endDate.getDate() - 6); // Start date is 7 days ago

      // Update your filter object with the calculated start and end dates
      this.listData.filter.startTime = this.formatDateToAPIFormat2(startDate);
      this.listData.filter.endTime = this.formatDateToAPIFormat2(endDate);

      this.loadGraph();
    },

    FormatDateToAPIFormat(date) {
      // Returns the date in 'dd-MM-yy' format
      return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear().toString().slice(2)}`;
    },

    // Returns the date in 'yyyy-MM-dd hh:mm:ss' format
    formatDateToAPIFormat2(date) {
      return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} `;
    },

  },

  created(){
    this.loadList();
    this.calculateTimeData();
  }
}
</script>

<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

.main-view {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 0 0 auto;
  padding: 0px;
}

p{
  font-family: Arial;
}

.subHeader {
  text-align: left;
  font-weight: bold;
  font-size: 15px; /* Adjust the font size as needed */
  margin-bottom: 10px; /* Add some spacing below the subheader */
  margin-top: 20px;
  margin-left: 25px;
}

.value{
  font-size: 40px;
  margin-top: 2px;
  margin-bottom: 0;
  color: #0a60f5;
  text-align: center;
}

.card-header {
  text-align: center;
  font-weight: bold;
}

.card-content {
  display: flex;
  height: 100%;
  padding: 0;
  margin: 0;
  border: 0;
}


.right-section {
  flex: 2;
}

.row-spacing {
  margin-bottom: 20px;
}

.center-align {
  text-align: center;
}

</style>