let apiHost = 'https://www.bestnow.live/api'
let nginxHost = 'https://www.bestnow.live/download'
// let apiHost = 'https://page.fun-club.tech/api'
// let nginxHost = 'https://page.fun-club.tech/download'

export default {
    title: '商户管理后台',
    homeName: 'index',
    baseUrl: {
        api: apiHost,
        static: nginxHost
    }
}