<template>
  <div class="login">
    <div style="border-radius: 30px; background-color: #f8f9fa; width:600px; max-height: 100vh">
    <el-form class="box-card" ref="loginForm" :model="LoginData" :rules="LoginRules" status-icon style="background: transparent; padding-top: 25%; max-height: 100vh"><br/><br/><br/>
      <img src='../assets/title.png' alt="Title" style="width: 475px; height: 175px; margin-bottom: -20px; margin-right: -5px; "><br/><br/>
      <span style="color: #1e6091; font-family:'Bahnschrift'; font-weight: lighter; font-size:40px; margin-top: 20px; text-align: left"> Login to get started</span><br/><br/>
      <span style="color:black; font-size: small; font-family: FangSong;">Please enter the following information to access your account</span><br/><br/>

      <el-form-item prop="loginName" style="margin-left: 120px">
        <el-input v-model="LoginData.loginName" type="text" placeholder="Please enter user name" style="width:350px;" size="large" clearable>
          <template #prefix>
            <el-icon><User /></el-icon>
          </template>
        </el-input>
      </el-form-item>

      <el-form-item prop="password" style="margin-left: 120px">
        <el-input v-model="LoginData.password" type="password" autocomplete="off" placeholder="Please enter password" style="width:350px;" size="large" show-password clearable>
          <template #prefix>
            <el-icon><Lock /></el-icon>
          </template>
        </el-input>
      </el-form-item>

      <el-form-item prop="googleCode" style="margin-left: 120px">
        <el-input v-model="LoginData.googleCode" type="number" placeholder="Please enter Google verification code" style="width:350px;" size="large" clearable>
          <template #prefix>
            <el-icon><Lock /></el-icon>
          </template>
        </el-input>
      </el-form-item>

      <el-form-item style="margin-left:190px;">
        <el-button @click="this.LoginClick" :loading="loginLoading" class="loginButton btn-hover" round >Login</el-button>
      </el-form-item>
    </el-form>
    </div>
  </div>
</template>


<script>
import { mapActions } from "vuex";
import { login } from "@/api/UserApi";

export default({
  data(){
    return{
      loginLoading: false,
      LoginData:{
        loginName : '',
        password : '',
        googleCode : '',
      },
      LoginRules:{
        loginName: [{ required: true, message: 'User name is mandatory', trigger: 'blur' },],
        password: [{ required: true, message: 'Password is mandatory', trigger: 'blur' },],
        googleCode: [{ required: true, message: 'Google verification code is mandatory', trigger: 'blur'},],
      }
    }
  },
  methods:{
    ...mapActions([
      'userLoginAction'
    ]),

    LoginClick() {
      var myLoginData = {
        loginName : this.LoginData.loginName,
        password : this.LoginData.password,
        googleCode : this.LoginData.googleCode
      }

      this.loginLoading = true
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          login(myLoginData).then(res => {
            if (res.code == 0) {
              this.userLoginAction(res.data)
              this.loginLoading = false
              localStorage.setItem('activeMenu', '1');
              this.$router.push('/dashboard');
            }
            else{
              this.loginLoading = false
              this.$message.error(res.msg);
            }
          }).catch((e) => {
            // console.log(e)
            this.loginLoading = false
          })

        } else {
          this.loginLoading = false
        }
      })
    }
  }
})

</script>


<style>
.login {
  width: 100%;
  height: 100vh;
  background: #fff url('../assets/login.jpg') no-repeat center; /* Center the background image */
  position: fixed;
  background-attachment: fixed; /* Make the background fixed */
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.box-card {
  width: 100%;
  height: 100vh;
  background-color: #ebd4cb;
  border: none;
  z-index: 10;
  text-align: center;
  padding: 20px;
  /*overflow: hidden;*/
}


.loginButton{
  /*background-image: linear-gradient(to right, #9a8c98, #a7939d, #b49ba1, #bfa4a4, #c9ada7);*/
  /*background-image: linear-gradient(to right bottom, #b5c6e0, #bdd3e5, #cadfea, #d9eaee, #ebf4f5);*/
  background-image: linear-gradient(to right, #05528e, #055899, #055ea3, #0565ae, #066bb9);
  width: 150px;
  color: white !important;
  outline: none;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
}

.loginButton:hover{
  color: #f4b340 !important;
  /*background-image: linear-gradient(to left, #b5c6e0, #bdd3e5, #cadfea, #d9eaee, #ebf4f5);*/
  background-image: linear-gradient(to left, #05528e, #055899, #055ea3, #0565ae, #066bb9);
  /*background-image: linear-gradient(to left, #9a8c98, #a7939d, #b49ba1, #bfa4a4, #c9ada7);*/
  text-align: center;
  outline: none;
  border: none;
}

.btn-hover {
  width: 200px;
  font-size: 16px;
  font-weight: 600;
  color: white;
  cursor: pointer;
  margin: 5px;
  height: 30px;
  text-align:center;
  border: none;
  background-size: 300% 100%;
  border-radius: 50px;
  -moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  outline: none;
  border: none;
}

.btn-hover:hover {
  background-position: 100% 0;
  -moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  text-align: center;
  outline: none;
  border: none;
  color: white;
}

</style>
